<template>
    <div class="page">
        <div>
            <SalesList v-if="!selectedSale" @refresh="fetchData" />
        </div>
        <SaleDetail v-if="selectedSale" :key="`ue${selectedSale.uberEventRef}`" @refresh="fetchData" />
    </div>
</template>

<script>
export default {
    watch: {
        'selectedSale.id'() {
            window.scroll(0, 0);
            if(!this.selectedSale) {
                this.$router.push({
                    query:{ id : null }
                })
                return
            }
            this.$router.push({
                query:{ id : this.selectedSale.number }
            })
        },
    },
    methods: {
        async fetchData() {
            await this.fetchAll('settings');
            await this.fetchMany('uberEvents', 'venues', 'promoters', 'globalDiscounts');
            await this.fetchMany('sales');

            this.selectedSale = this.selectedSale && this.findIn(this.sales, this.selectedSale);
        },
    },

    @cancellableFetch
    async created() {
        await this.fetchData();
        if(this.$route.query && this.$route.query.id){
            try{
                this.selectedSale = await this.sales.find(sale => sale.number === this.$route.query.id)
            }catch (e) {
                this.selectedSale = null
            }
        }else{
            this.selectedSale = null
        }
    },

    meta: {
        title: 'Transakce',
    },
};
</script>
